import React, {useState} from "react"
import Layout from '../../layout/layout';
import AddNewStore from './AddNewStore/AddNewStore';
import StoreList from './StoreList/StoreList';
import { Container, Row, Col,Button, Card } from 'reactstrap';
import './Stores.scss';

const Stores = (props) =>{

   const [shownewStore, setshownewStore] = useState(true);
   const [showlistStore, setshowlistStore] = useState(false);
   const [showmyStore, setshowmyStore] = useState(false);

   const activated = (e) => {
      if(e){
         
         switch(e.target.id) {
            case 'newStore':
               setshownewStore(true);
               setshowlistStore(false);
               setshowmyStore(false);
               break;
            case 'listStore':
               setshownewStore(false);
               setshowlistStore(true);
               setshowmyStore(false);
               break;
            case 'myStore':
               setshownewStore(false);
               setshowlistStore(false);
               setshowmyStore(true);
               break;   
            default:
               break;   
         }

      }
   }

   const download = () => {

      const bearerToken = () => {
      
         const session =  JSON.parse(localStorage.getItem('userSession'));
         
         if (session) {
            
            return `Bearer ${session.access_token}`;
   
         } else {
   
            return null;
   
         }
            
      }

      //const endPoint = `https://littlecaesarsapi.ktbo.mx/api/auth/exportstore`;
      //const endPoint = 'https://api.littlecaesars.hubcrm.net/api/auth/exportstore';
      //const endPoint = 'https://api.littlemxstg.ktbo.com/api/auth/exportstore';
      const endPoint = 'https://api-littlecaesars.hubcrm.net/api/auth/exportstore';

      const request = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': bearerToken()
        }
      }

      fetch(endPoint, request)
      .then(response => response.blob())
      .then(blob => {
         var url = window.URL.createObjectURL(blob);
         var a = document.createElement('a');
         a.href = url;
         a.download = "file.csv";
         document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
         a.click();    
         a.remove();  //afterwards we remove the element again         
      });
       
   }

   return (

      <Layout>
         <div className="overview">
            <Container>
               {/* <h2 className="emigre-bold mt-5">Administrador de Sucursales</h2> */}
            </Container>
            <Container className='customers'>
               <Row>
                  <Col sm="4">
                  </Col>
                  <Col></Col>
                  <Col sm="6">
                     <Button  
                     size='lg' 
                     className='bg-dark directorios_decarga_buttom emigre-bold' 
                     block
                     onClick={download}
                     >
                        
                        DESCARGAR DIRECTORIO DE SUCURSALES <i className="icomoon icon-save ml-2"></i></Button>
                  </Col>
               </Row>
               <br />
               <Row>
                  <Col xs="12">
                     <Card body tag="article">
                        <Row>
                           <Col xs='12' sm="4">
                              <Button id='newStore' size='lg' className={`${shownewStore ? 'bg-primary directorios_decarga_buttom' : 'bg-dark directorios_decarga_buttom'} emigre-bold`} block
                                 onClick={(e) => {activated(e)}}
                              >AGREGAR NUEVA SUCURSAL</Button>
                           </Col>
                           <Col xs='12' sm="4">
                              <Button id='listStore' size='lg' className={`${showlistStore ? 'bg-primary directorios_decarga_buttom' : 'bg-dark directorios_decarga_buttom'} emigre-bold`} block
                              onClick={(e) => {activated(e)}}>VER LISTADO COMPLETO</Button>
                           </Col>
                           <Col xs='12' sm="4">
                              <Button id='myStore' size='lg' className={`${showmyStore ? 'bg-primary directorios_decarga_buttom' : 'bg-dark directorios_decarga_buttom'} emigre-bold`} block
                              onClick={(e) => {activated(e)}}>MIS SUCURSALES</Button>
                           </Col>
                        </Row>
                     </Card>
                  </Col>
               </Row>

               <br />
               <Row>
               <Col xs="12">
                     <Card body tag="article">
                        <Row>
                           {
                              shownewStore ? 
                              <Col lg='12'>
                                 <AddNewStore />
                              </Col> :
                              null
                           }
                           {
                              showlistStore ?

                              <Col lg='12' className='big-table'>
                                 <StoreList myStores={false} />
                              </Col> :
                              null
                           }
                           {
                              showmyStore ? 
                              <Col lg='12'>
                                 <StoreList myStores={true}/>
                               </Col>
                              :
                              null
                           }
                           
                        </Row>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>
      </Layout>

   );
}


export default Stores;